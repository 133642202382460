import { LANGUAGE_FORMATTED_LIST } from "../../constants/i18n";
import { handleCmsDateWithLocale } from ".";
import type { ArticleNode, Block, Crop, Hotspot, SanityImage } from "../../interface/sanityNode";
import type { Cards } from "../../components/PostCard";

const MIN_CHARACTER = 100;
// the excerpt will be sourced from sanity after testing v3 is compatible and steady for migration
export const handleCmsArticleContent = (rawArticleContent: { en: Array<Block> }) => {
  const firstParagraphBlock = rawArticleContent.en.find(
    ({ _type, style }) => _type === "block" && style === "normal"
  );

  if (!firstParagraphBlock || !firstParagraphBlock.children?.[0].text) {
    return "";
  }

  if (firstParagraphBlock.children?.[0].text.length < MIN_CHARACTER) {
    return firstParagraphBlock.children.reduce((clip, { text }) => (clip += text), "");
  }

  return firstParagraphBlock.children[0].text || "";
};

const handleRawImage = (image: SanityImage) => ({
  rawImgSource: image?.asset || { _ref: "" },
  imgCrop: image?.crop || undefined,
  imgHotspot: image?.hotspot || undefined,
});

export const handleCmsArticleRelatedData = (nodes: Array<ArticleNode>) =>
  LANGUAGE_FORMATTED_LIST.map((lang) =>
    nodes.map((n) => ({
      _id: n._id,
      title: n._rawArticleTitle[lang] ? n._rawArticleTitle[lang] : n._rawArticleTitle.en,
      rawParagraph: n._rawArticleContent[lang]
        ? n._rawArticleContent[lang]
        : n._rawArticleContent.en,
      firstParagraph: handleCmsArticleContent(n._rawArticleContent),
      articleAuthor: n.articleAuthor,
      rawMainImg: handleRawImage(n._rawMainImage),
      rawBannerImg: n._rawBannerImage ? handleRawImage(n._rawBannerImage) : undefined,
      articlePublishedLocalTime: handleCmsDateWithLocale(n.articlePublishedTime, lang),
      articlePublishedISOTime: new Date(n.articlePublishedTime).toISOString(),
      slug: n._rawArticleSlug.current,
      updatedAt: n._updatedAt,
    }))
  );

type RawImage = {
  rawImgSource: {
    _ref: string;
  };
  imgCrop: Crop | undefined;
  imgHotspot: Hotspot | undefined;
};

export type ArticleRelatedData = {
  _id: string;
  title: string | undefined;
  rawParagraph: Array<Block> | undefined;
  firstParagraph: string;
  articleAuthor: string;
  rawMainImg: RawImage;
  rawBannerImg: RawImage | undefined;
  articlePublishedLocalTime: string;
  articlePublishedISOTime: string;
  slug: string;
  updatedAt: string;
};

export const handleArticleRelatedDataToCardProps = (
  data: Array<ArticleRelatedData>,
  hrefPrefix: string
): Cards =>
  data.map((d) => ({
    date: d.articlePublishedLocalTime,
    image: {
      mainImg: d.rawMainImg.rawImgSource._ref,
      crop: d.rawMainImg.imgCrop,
      hotspot: d.rawMainImg.imgHotspot,
    },
    title: d.title || "",
    paragraphClip: d.firstParagraph,
    href: `${hrefPrefix}/${d.slug}`,
  }));

type ArticleImg = {
  mainImg: string;
  crop: Crop | undefined;
  hotspot: Hotspot | undefined;
};

export type BlogPostData = {
  title: string;
  localDate: string;
  isoDate: string;
  author: string;
  mainImg: ArticleImg;
  bannerImg: ArticleImg | undefined;
  paragraphBlock: Block[] | undefined;
  slug: string;
  updatedAt: string;
};

export const handleArticleRelatedDataToBlogPostProps = (
  data: Array<ArticleRelatedData>
): Array<BlogPostData> =>
  data.map((d) => ({
    title: d.title || "",
    localDate: d.articlePublishedLocalTime,
    isoDate: d.articlePublishedISOTime,
    author: d.articleAuthor,
    mainImg: {
      mainImg: d.rawMainImg.rawImgSource._ref,
      crop: d.rawMainImg.imgCrop,
      hotspot: d.rawMainImg.imgHotspot,
    },
    bannerImg: d.rawBannerImg
      ? {
          mainImg: d.rawBannerImg.rawImgSource._ref,
          crop: d.rawBannerImg?.imgCrop,
          hotspot: d.rawBannerImg?.imgHotspot,
        }
      : undefined,
    paragraphBlock: d.rawParagraph,
    slug: d.slug,
    updatedAt: d.updatedAt,
  }));
