import React, { FC } from "react";

import { observer } from "mobx-react-lite";

import { useInitBlogCardsData } from "../hooks";
import { FirstBlogCard } from "./FirstBlogCards";
import { PostCard } from "components/PostCard";

import type { blogStore } from "../store";

interface CardListProps {
  articleStore: typeof blogStore;
  emptyPaddingItems: number;
}

const CardList: FC<CardListProps> = observer(({ articleStore, emptyPaddingItems }) => {
  const initCards = useInitBlogCardsData(emptyPaddingItems);
  const currentCards = articleStore.cards.length !== 0 ? articleStore.cards : initCards;
  const isFirstPage = currentCards.some((card) => typeof card !== "object");

  if (isFirstPage) {
    const [firstCard, ...restCards] = currentCards.filter((card) => card);
    return (
      <>
        <FirstBlogCard {...firstCard} />
        {restCards.map((card) => (
          <PostCard {...card} key={card.title} />
        ))}
      </>
    );
  }

  return (
    <>
      {currentCards.map((card, i) => (
        <PostCard {...card} key={i} />
      ))}
    </>
  );
});

export { CardList };
