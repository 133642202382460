import { graphql, useStaticQuery } from "gatsby";

import type { AllSanityBlog } from "interface/sanityNode";
import { useStaticTotalCardItems } from "../ArticlePagination/hooks";

const initBlogAritclesQuery = graphql`
  query GetInitBlog {
    allSanityBlog(sort: { fields: articlePublishedTime, order: DESC }, limit: 7) {
      nodes {
        _id
        _rawArticleContent
        _rawArticleSlug
        _rawArticleTitle
        _rawMainImage
        articleAuthor
        articlePublishedTime
      }
    }
  }
`;

export const useInitBlogCardsData = (emptyPaddingItems: number = 0) => {
  const { nodes } = (useStaticQuery(initBlogAritclesQuery) as AllSanityBlog).allSanityBlog;

  return useStaticTotalCardItems(nodes, emptyPaddingItems);
};
