import React, { FC } from "react";

import { CardProps, DefaultImg, PostCard, ReadMore } from "components/PostCard";
import { SanityImage } from "components/SanityImage";
import { CustomLink } from "components/CustomLink";

const FirstBlogCardContent: FC<CardProps> = ({ date, image, title, paragraphClip, href }) => {
  const { mainImg } = image;
  return (
    <CustomLink
      href={href}
      className="pointer-events-none col-start-1 col-end-4 hidden h-96 w-full max-w-none cursor-pointer flex-row gap-x-8 overflow-hidden rounded-xl bg-btq-card-bg-dark xl:pointer-events-auto xl:flex"
    >
      <div className="h-full flex-1">
        {mainImg === "" ? (
          <DefaultImg />
        ) : (
          <SanityImage
            width={903}
            _ref={mainImg}
            alt={title}
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="flex w-[20rem] flex-none flex-col gap-y-3 pl-0 pt-4 pr-4 pb-7">
        <span className="text-sm font-bold text-btq-sub-text-normal">{date}</span>
        <h3 className="flex-none text-lg font-semibold leading-6 text-white line-clamp-2">
          {title}
        </h3>
        <p className="flex-none text-btq-sub-text-normal line-clamp-9 ">{paragraphClip}</p>
        <ReadMore className="mt-auto" />
      </div>
    </CustomLink>
  );
};

const FirstBlogCard: FC<CardProps> = (cardProps) => {
  return (
    <>
      <FirstBlogCardContent {...cardProps} />
      <div className="xl:hidden">
        <PostCard {...cardProps} />
      </div>
    </>
  );
};

export { FirstBlogCard };
