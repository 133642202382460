import { useCallback, useContext } from "react";
import type { ArticleNode } from "interface/sanityNode";
import { i18nContext } from "context/i18n";
import { LANGUAGE_FORMATTED_LIST } from "constants/i18n";
import {
  handleArticleRelatedDataToCardProps,
  handleCmsArticleRelatedData,
} from "utils/cms/article";
import { SLUG } from "constants/slug";

const useCmsArticlesAsCardsHandler = () => {
  const { locale } = useContext(i18nContext);

  const getHandleCards = useCallback(
    (data: Array<ArticleNode>) => {
      const localeIndex = LANGUAGE_FORMATTED_LIST.indexOf(locale);
      const rawArticles = handleCmsArticleRelatedData(data);
      return handleArticleRelatedDataToCardProps(
        rawArticles[localeIndex] || rawArticles[0],
        SLUG.BLOG
      );
    },
    [locale]
  );

  return { getHandleCards };
};

export default useCmsArticlesAsCardsHandler;
