import React, { lazy, Suspense, FC, HTMLProps, RefObject } from "react";

import { observer } from "mobx-react-lite";

import { CardList } from "./CardList";
import { blogStore } from "./store";

const Pagination = lazy(() =>
  import("./BlogPagination").then(({ BlogPagination }) => ({
    default: BlogPagination,
  }))
);

const CardContainer: FC<HTMLProps<HTMLDivElement>> = ({ children }) => {
  return (
    <div className="mt-6 grid w-full max-w-max grid-cols-1 justify-items-center gap-y-4 lg:mt-10 lg:grid-cols-2 lg:gap-y-10 lg:gap-x-7 xl:max-w-none xl:grid-cols-3 xl:justify-items-start xl:gap-x-10">
      {children}
    </div>
  );
};

enum PAGINATION {
  EMPTY_PADDING_ITEMS = 2,
  ITEMS_PER_PAGE = 9,
}

interface BlogPaginationProps {
  articleStore: typeof blogStore;
  onPageChangeEffect?: () => void;
}

const BlogPagination: FC<BlogPaginationProps> = observer(({ articleStore, onPageChangeEffect }) => (
  <Pagination
    currentItem={articleStore.currentItem}
    onPageSelected={(currentItem) => {
      articleStore.setCurrentItem(currentItem);
    }}
    onPageChange={(cards) => {
      articleStore.setCards(cards);
      onPageChangeEffect?.();
    }}
    emptyPaddingItems={PAGINATION.EMPTY_PADDING_ITEMS}
    itemsPerPage={PAGINATION.ITEMS_PER_PAGE}
  />
));

const SectionContent = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => (
  <>
    <CardContainer>
      <CardList articleStore={blogStore} emptyPaddingItems={PAGINATION.EMPTY_PADDING_ITEMS} />
    </CardContainer>
    <Suspense>
      <BlogPagination
        articleStore={blogStore}
        onPageChangeEffect={() => {
          scrollRef?.current?.scrollIntoView({ block: "start" });
        }}
      />
    </Suspense>
  </>
);

export { SectionContent };
