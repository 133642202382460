import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { SectionContent } from "./SectionContent";

import COLOR from "constants/design";

const BlogCards = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Section background={{ color: COLOR.BACKGROUND_DARK }}>
      <div className="flex min-h-screen flex-col items-center pt-32 pb-12 xl:items-start xl:pt-48 xl:pb-24">
        <Title ref={scrollRef}>
          <FormattedMessage id="BLOG_TITLE_LATEST_NEWS" />
        </Title>
        <SectionContent scrollRef={scrollRef} />
      </div>
    </Section>
  );
};

export default BlogCards;
