import { useMemo } from "react";

import useCmsArticlesAsCardsHandler from "hooks/useCmsArticlesAsCardsHandler";

import type { ArticleNode } from "interface/sanityNode";
import { Cards } from "components/PostCard";

export const useStaticTotalCardItems = (
  cmsData: Array<ArticleNode>,
  emptyPaddingItems: number = 0
) => {
  const { getHandleCards } = useCmsArticlesAsCardsHandler();

  const totalCardItems = useMemo<Cards>(
    () =>
      Array(emptyPaddingItems > 0 ? emptyPaddingItems : 0)
        .fill(undefined)
        .concat(getHandleCards(cmsData)),
    [cmsData, emptyPaddingItems, getHandleCards]
  );

  return totalCardItems;
};
