import { observable, action, makeObservable } from "mobx";
import { CardProps } from "components/PostCard";

export default class ArticleDataStore {
  cards = observable<CardProps>([]);
  @observable currentItem = 0;

  private isDataInited = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public setCards(cards: CardProps[]) {
    this.cards.replace(cards);
  }

  @action
  public setCurrentItem(currentItem: number) {
    this.currentItem = currentItem;
  }

  @action initCards(cards: CardProps[]) {
    if (!this.isDataInited) {
      this.setCards(cards);
      this.isDataInited = true;
    }
  }
}
