import React from "react";
import type { PageProps } from "gatsby";
import { useIntl } from "react-intl";

import withI18n from "i18n/withI18n";
import { Layout } from "components/Layout";
import BlogCards from "views/blog/blogCards";
import SEO from "components/seo";

const BlogPage = ({ location }: PageProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "BLOG_PAGE_TITLE" })}
        pathname={location.pathname}
        titlePrefix
        locale
      />
      <Layout>
        <BlogCards />
      </Layout>
    </>
  );
};

export default withI18n(BlogPage);
