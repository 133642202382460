import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { CustomLink } from "./CustomLink";
import { SanityImage } from "./SanityImage";
import { StaticImage } from "gatsby-plugin-image";

import { Crop, Hotspot } from "interface/sanityNode";

export type Image = {
  mainImg: string;
  crop: Crop | undefined;
  hotspot: Hotspot | undefined;
};
export interface CardProps {
  date: string;
  image: Image;
  title: string;
  paragraphClip: string;
  href: string;
}

export type Cards = Array<CardProps>;

export const ReadMore = ({ className = "" }) => (
  <div className={`pointer-events-auto cursor-pointer text-end ${className}`}>
    <span className="text-lg font-semibold leading-6 text-primary xl:block">
      <FormattedMessage id="NEWSROOM_AND_BLOG_CARD_BUTTON_READMORE" />
    </span>
  </div>
);

export const DefaultImg = () => {
  return (
    <StaticImage
      src="../assets/default_main_img.png"
      alt="default img for articles"
      className="h-full w-full object-cover"
    />
  );
};

const PostCard: FC<CardProps> = ({ date, image, title, paragraphClip, href }) => (
  <CustomLink href={href} className="pointer-events-none xl:pointer-events-auto">
    <div className="flex h-[31.25rem] w-full max-w-[25rem] flex-col overflow-hidden rounded-xl bg-btq-card-bg-dark">
      <div className="h-1/2 w-full">
        {image.mainImg === "" ? (
          <DefaultImg />
        ) : (
          <SanityImage
            width={400}
            _ref={image.mainImg}
            crop={image.crop}
            hotspot={image.hotspot}
            alt={title}
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="flex h-1/2 flex-col gap-y-2 pl-7 pt-7 pr-8 pb-8">
        <span className="text-sm text-btq-sub-text-normal">{date}</span>
        <h3 className="flex-none pb-2 text-lg font-semibold leading-6 text-white line-clamp-2">
          {title}
        </h3>
        <p className="mb-4 flex-none text-btq-sub-text-normal line-clamp-2">{paragraphClip}</p>
        <ReadMore className="mt-auto" />
      </div>
    </div>
  </CustomLink>
);

export { PostCard };
